import axios from "axios";

const state = {
    permissions: [],
    canReadAttendance: false,
    canCreateAttendance: false,
    canUpdateAttendance: false,
    canDeleteAttendance: false,
    canApproveAttendance: false,
    canReadTrainee: false,
    canApproveSubsidy: false,
    canApproveTraining: false,
}
const mutations = {
    permissions(state, value) {
        state.permissions = value
        state.canReadAttendance = state.permissions['attendance'] ? state.permissions['attendance'].includes('attendance-read') : false
        state.canCreateAttendance = state.permissions['attendance'] ? state.permissions['attendance'].includes('attendance-create') : false
        state.canUpdateAttendance = state.permissions['attendance'] ? state.permissions['attendance'].includes('attendance-update') : false
        state.canDeleteAttendance = state.permissions['attendance'] ? state.permissions['attendance'].includes('attendance-delete') : false
        state.canApproveAttendance = state.permissions['attendance'] ? state.permissions['attendance'].includes('attendance-status') : false
        state.canReadTrainee = state.permissions['trainee'] ? state.permissions['trainee'].includes('trainee-read') : false
        state.canApproveSubsidy = state.permissions['subsidy'] ? state.permissions['subsidy'].includes('subsidy-status') : false
        state.canApproveTraining = state.permissions['training'] ? state.permissions['training'].includes('training-status') : false
    }
}
const getters = {
    permissions: state => state.permissions,
    canReadAttendance: state => state.canReadAttendance,
    canCreateAttendance: state => state.canCreateAttendance,
    canUpdateAttendance: state => state.canUpdateAttendance,
    canDeleteAttendance: state => state.canDeleteAttendance,
    canApproveAttendance: state => state.canApproveAttendance,
    canReadTrainee: state => state.canReadTrainee,
    canApproveSubsidy: state => state.canApproveSubsidy,
    canApproveTraining: state => state.canApproveTraining,
}
const actions = {
    getNavigations(context, params) {
        if (context.getters.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + params.token
            return new Promise((resolve, reject) => {
                axios.get(`/navigation`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
